import { TFF } from '@tff/types';
import { useRebook } from '../components/RebookFlight/RebookContext';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { format } from 'date-fns';
import { useContext } from 'react';
import { apigeeEndpoint } from '../apis/apigeeEndpoint';
import { TfmFlightSearchRequestHandlerOutput } from '@tff/types/TFM';
import * as dateFns from 'date-fns';

const useFetchFlightAlternatives = (segments: TFF.Segment[], orderDetails: TFF.OrderDetails) => {
  const filterSevenDaysOfOffers = (offers: TFF.Offer[], depDate: string | Date): TFF.Offer[] => {
    const filteredOffers: TFF.Offer[] = [];
    let startDate = dateFns.addDays(new Date(depDate), -3);
    for (let i = 0; i < 7; i++) {
      const countedDate = dateFns.addDays(new Date(startDate), i).toISOString().split('T')[0];
      const dayOffers: TFF.Offer[] | undefined = offers.filter(
        (offer: TFF.Offer) => String(offer.StartDate).split('T')[0] === countedDate,
      );
      dayOffers?.sort((o1, o2) => o1.TotalPrice! - o2.TotalPrice!);
      filteredOffers.push(...dayOffers);
    }
    return filteredOffers;
  };
  const {
    rebookSearchState,
    toggleFlexibleOutboundFlights,
    toggleFlexibleReturnFlights,
    toggleOutboundFlights,
    toggleReturnFlights,
    toggleDisplay,
    incrementSearchedDirectionCount,
  } = useRebook();
  const { showLoading, closeLoading } = useContext(LoadingContext);

  const defaultDeparture = format(new Date(segments[0].DepartureTime), 'yyyy-MM-dd');
  const defaultOrigin = segments[0].Origin;
  const defaultDestination = segments[segments.length - 1].Destination;

  const fetchFlightAlternatives = async (direction: 'Outbound' | 'Return') => {
    const [
      DEPARTURE,
      ORIGIN,
      DESTINATION,
      INF,
      ADT,
      CHD,
      CURRENT_CARRIER_CODE,
      CURRENT_FARE_BASE,
      CURRENT_TOTAL_FARE_AMOUNT,
      IGNORE_RESTRICTIONS,
    ] = rebookSearchState[direction][0];
    console.log(`[${direction}] Fetching flight alternatives...`);
    try {
      incrementSearchedDirectionCount();

      showLoading('Searching Alternative Flights');
      const url = `flight-amend/search?origin=${ORIGIN}&destination=${DESTINATION}&departureDate=${DEPARTURE}&adult=${ADT}&child=${CHD}&infant=${INF}&currentFareBase=${CURRENT_FARE_BASE}&currentCarrierCode=${CURRENT_CARRIER_CODE}&currentTotalFareAmount=${CURRENT_TOTAL_FARE_AMOUNT}&ignoreRestrictions=${IGNORE_RESTRICTIONS}`;
      const response = await apigeeEndpoint.get<TfmFlightSearchRequestHandlerOutput>(url);
      if (response?.data) {
        let allOffers = response.data.offers ?? [];
        let dayOffers: TFF.Offer[] = [];
        let flexOffers: TFF.Offer[] = [];

        if (allOffers) {
          dayOffers = allOffers.filter(o => String(o.StartDate).startsWith(DEPARTURE));
          flexOffers = filterSevenDaysOfOffers(allOffers, DEPARTURE);
        }

        if (direction === 'Outbound') {
          toggleFlexibleOutboundFlights(flexOffers);
          toggleOutboundFlights(dayOffers);
        } else if (direction === 'Return') {
          toggleReturnFlights(dayOffers);
          toggleFlexibleReturnFlights(flexOffers);
        }

        if (response.data.offers && response.data.offers?.length > 0) {
          toggleDisplay(true);
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
      } else {
        toggleDisplay(false);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      toggleDisplay(false);
    } finally {
      closeLoading();
    }
  };
  return fetchFlightAlternatives;
};

export default useFetchFlightAlternatives;
