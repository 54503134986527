import { Box, Button } from '@mui/material';
import React from 'react';

interface props {
  rebookCloser: (value: boolean) => void;
  handleRequest: () => Promise<void>;
  enableSearchAlternatives: boolean;
  cancelHandler: () => void;
}

const SearchAlternatives: React.FC<props> = ({
  rebookCloser,
  handleRequest,
  enableSearchAlternatives,
  cancelHandler,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          marginTop: '25px',
          padding: '0',
        }}
      >
        <Button
          color="info"
          style={{
            backgroundColor: '#F4F4F7',
            borderRadius: '3px',
            color: '#09295D',
            borderColor: '#09295D',
            border: '1px solid',
          }}
          onClick={() => {
            rebookCloser(false);
            cancelHandler();
          }}
        >
          CANCEL
        </Button>
        <Button
          color="info"
          style={{
            backgroundColor: enableSearchAlternatives ? '#092A5E' : 'grey',
            color: 'white',
            borderRadius: '3px',
            paddingLeft: 16,
            paddingRight: 16,
          }}
          onClick={() => handleRequest()}
          disabled={!enableSearchAlternatives}
        >
          SEARCH ALTERNATIVES
        </Button>
      </Box>
    </>
  );
};

export default SearchAlternatives;
