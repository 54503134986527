import React from 'react';
import { MuiAccordion, MuiAccordionSummary, MuiAccordionDetails } from './MuiAccordion';
import { useStyles } from './use-styles';
import { Typography } from '@mui/material';
import RebookEditSegments from '../RebookEditSegments';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as TFF from '@tff/types/TFF';

interface props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  airports: TFF.IAirport[];
  retrieveDb?: TFF.Retrieve;
  setEnableSearchAlternatives: (value: boolean) => void;
}

const FlightDetails: React.FC<props> = ({
  orderDetails,
  journey,
  airports,
  retrieveDb,
  setEnableSearchAlternatives,
}) => {
  const classes = useStyles();
  const isNotExpired = (dateString: string): boolean => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return currentDate > givenDate;
  };
  const liftStatus: string = journey?.LiftStatusAggregated?.[0] || '';

  return (
    <div className={classes.flightDetailRoot}>
      <MuiAccordion>
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={isNotExpired(journey.Segments[0].DepartureTime) || liftStatus !== 'Default'}
          onClick={() => setEnableSearchAlternatives(true)}
        >
          <Typography component={'span'} className={classes.heading} style={{ fontSize: '18px' }}>
            Edit Flight
          </Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <RebookEditSegments
            orderDetails={orderDetails}
            journey={journey}
            airports={airports}
            retrieveDb={retrieveDb}
          />
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default FlightDetails;
